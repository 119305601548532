import React from "react";

import Layout from "../components/general/Layout";

const NotFoundPage = () => (
    <Layout>
        <h1>NOT FOUND</h1>
        <p>The requested page was not found.</p>
    </Layout>
);

export default NotFoundPage;
